(function () {
    'use strict';

    /**
     OTDRPresetService factory
     STATELESS and IMMUTABLE, please leave like so
     produces models prototyped with delete/update functionality.

     which should be removed as soon as we have consistent API convention
     */
    angular.module('aerosApp').factory('OTDRPresetService', OTDRPresetService);

    OTDRPresetService.$inject = ['aerosApi', '$q', 'ToolsDefinitionService'];

    function OTDRPresetService(aerosApi, $q, ToolsDefinitionService) {
        var flat2nested = ToolsDefinitionService.format.flat2nested;
        var nested2flat = ToolsDefinitionService.format.nested2flat;
        var ModelPrototype = {
            destroy: deletePreset,
            update: updatePreset
        };

        return {
            fetch: getPresets,
            create: createPreset
        };

        function getPresets(organizationID) {
            return aerosApi.otdrToolLibraryAPI.settings.list(organizationID)
                .then(function (res) {
                    return res.data;
                })
                .then(markGlobalLimits)
                .then(mergeSettings)
                .then(transform)
                .then(function (presets) {
                    return {OTDR: presets};
                });
        }

        function createPreset(orgId, data, presetType) {
            return aerosApi.otdrToolLibraryAPI.settings.post(orgId, _.defaults({ // TODO: unify to serialize
                name: data.name,
                type: capitalize(data.type || data.group || presetType)
            }, flat2nested(data.fields)));
        }

        function deletePreset() {
            return aerosApi.otdrToolLibraryAPI.settings.delete(this.orgId, this.id);
        }

        function updatePreset(data) {
            return aerosApi.otdrToolLibraryAPI.settings.update(this.orgId, this.id, serialize(data));
        }

        function serialize(model) {
            return _.defaults({
                name: model.name,
                uuid: model.id,
                type: capitalize(model.type || model.group) // TODO: infy to type
            }, flat2nested(model.fields));
        }

        function capitalize(str) {
            return str.slice(0, 1).toUpperCase() + str.slice(1);
        }

        function markGlobalLimits(data) {
            if (data.globalLimits) {
                Object.keys(data.globalLimits).forEach(function (item) {
                    if (angular.isArray(data.globalLimits[item]))
                        data.globalLimits[item].forEach(function (limit) {
                            limit.generic = true;
                        });
                });
            }

            return data;
        }

        function mergeSettings(data) {
            return _.mergeWith(data.organizationlimits, data.globalLimits, merge);

            function merge(objValue, srcValue) {
                return _.isArray(objValue) ?
                    objValue.concat(srcValue) :
                    objValue;
            }
        }

        // @private
        function transform(presets) {
            return _.chain(presets).pick(['eventPassFailCriteria', 'eventDetectionCriteria', 'linkPassFail']).mapValues(function (val, group) {                           // transform values
                return val.map(_.partial(transItem, group));              // currying group by _.partial
            }).value();
        }

        // @private
        function transItem(group, item) {
            var fields = _.omit(item, 'uuid', 'name', 'organizationId');
            return Object.create(ModelPrototype, {
                id: {value: item.uuid, enumerable: true},
                orgId: {value: item.organizationId, enumerable: true},
                name: {value: item.name, enumerable: true},
                toolType: {value: 'OTDR', enumerable: true},
                group: {value: group, enumerable: true},
                fields: {value: nested2flat(fields), enumerable: true},
                generic: {value: !!item.generic, enumerable: false}
            });
        }

    }

}());